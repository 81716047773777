import React, { useEffect } from "react";
import { Provider, useSelector, useDispatch } from "react-redux";
import { store, persistor } from "./store";
import { PersistGate } from "redux-persist/integration/react";
import {
  HashRouter as Router,
  Switch,
  Redirect,
  useHistory 
} from "react-router-dom";
import "moment/locale/es";
import { Route } from "components";
import { RootState } from "reducers";
import { Socket, SocketEvents, Constants, Globals } from 'utils';
import { AuthService } from 'services';

import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

// Public
import Home from 'screens/home';

// Auth
import Login from "screens/auth/login/login";
import Recover from "screens/auth/login/recover";
import Reset from "screens/auth/login/reset";
import Profile from "screens/profile";
import Verify from "screens/auth/register/verify";
import Register from "screens/auth/register/register";

// Admin
import AdminUsers from 'screens/admin/users/users';
import AdminPlans from 'screens/admin/plans/plans';
import AdminReports from 'screens/admin/reports/reports';
import AdminReportVerifications from 'screens/admin/reports/verifications/verifications';
import AdminReportBlackList from 'screens/admin/reports/black-list/black-list';
import AdminViewVerification from 'screens/admin/reports/verifications/view';

// Client
import ClientHome from 'screens/client/home/home';
import ClientApi from 'screens/client/api/api';
import ClientReports from 'screens/client/reports/reports';
import ClientReportVerifications from 'screens/client/reports/verifications/verifications';

// Scripts
import "jquery/dist/jquery.min.js";
import "bootstrap/dist/js/bootstrap.min.js";

// Styles
import "bootstrap/dist/css/bootstrap.min.css";
import "font-awesome/css/font-awesome.min.css";
import "./scss/main.scss";

const Container = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    const getUser = async () => {
      const user = store.getState().user;
      if (user) {
        const res: any = await AuthService.checkUser({
          withoutLoading: true
        });
        if (res.user) {
          dispatch({
            type: 'SET_USER',
            payload: res.user
          });
        }
        else {
          Globals.showError("Lo sentimos, su usuario ha sido eliminado");
          close();
        }
      }
    }
    getUser();

    const close = async () => {
      await dispatch({
        type: 'SET_USER',
        payload: null
      }); 
      await dispatch({
        type: 'SET_ACCESS_TOKEN',
        payload: null
      }); 
      history.replace('/logout');
    }

    Socket.on(SocketEvents.UPDATE_USER,(data: any) => {
      const user = store.getState().user;
      if (user != null && user?.id == data.user_id) {
        getUser();
      }
    });
  },[]);

  return (
    <React.Fragment>
      <Switch>
        {/* Public */}
        <Route exact path="/" component={ Home } />

        {/* Auth */}
        <Route exact noAuth path="/login" component={ Login } />
        <Route exact auth path="/profile" component={ Profile } />
        <Route exact noAuth path="/recover" component={ Recover } />
        <Route exact noAuth path="/recover/:code" component={ Reset } />
        <Route exact noAuth path="/verify/:code" component={ Verify } />
        <Route exact noAuth path="/register" component={ Register } />

        {/* Admin */}
        <Route exact admin path="/admin/users" component={ AdminUsers } />
        <Route exact admin path="/admin/plans" component={ AdminPlans } />
        <Route exact admin path="/admin/reports" component={ AdminReports } />
        <Route exact admin path="/admin/reports/verifications" component={ AdminReportVerifications } />
        <Route exact admin path="/admin/reports/verifications/view/:id" component={ AdminViewVerification } />
        <Route exact admin path="/admin/reports/black-list" component={ AdminReportBlackList } />

        {/* Client */}
        <Route exact client path="/client/home" component={ ClientHome } />
        <Route exact client path="/client/api" component={ ClientApi } />
        <Route exact client path="/client/reports" component={ ClientReports } />
        <Route exact client path="/client/reports/verifications" component={ ClientReportVerifications } />
        <Route exact client path="/client/reports/verifications/view/:id" component={ AdminViewVerification } />

        <Redirect to="/" />
      </Switch>
    </React.Fragment>
  )
}

const App = () => (
  <Provider store={ store }>
    <PersistGate loading={ null } persistor={ persistor }>
      <Router>
        <Container />
      </Router>
    </PersistGate>
  </Provider>
);

export default App;
