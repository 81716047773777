import React from 'react';
import { Input, Button, Select } from 'components';
import { RootState } from 'reducers';
import { RouteComponentProps } from 'react-router-dom';
import { Globals, Constants } from 'utils';
import { PlanService } from 'services';

interface Props {
	onClose?: any;
	item?: any;
}

class ModalCreate extends React.Component<Props> {

	state = {
		form: {
			name: '',
			price: '',
			quantity: ''
		},
	}

	componentDidMount() {
		if (this.props.item) {
			const item: any = this.props.item;

			this.setState({
				form: {
					...this.state.form,
					name: item?.name || '',
					quantity: item?.quantity || '',
					price: item?.price || ''
				}
			});
		}
	}

	submit = async (e: any) => {
		e.preventDefault();

		if (this.props.item) {
			await PlanService.admin.save({
				...this.state.form,
				plan_id: this.props.item?.id
			});
			this.props.onClose();
			Globals.showSuccess("Se ha modificado el plan correctamente");
		}
		else {
			await PlanService.admin.save({
				...this.state.form
			});
			this.props.onClose();
			Globals.showSuccess("Se ha registrado el plan correctamente");
		}
	}

	change = (e: any,callback = () => {}) => {
		this.setState({
			page: 1,
			form: {
				...this.state.form,
				[e.target.name]: e.target.value
			}
		},callback);
	}
	
	render() {
		return (
			<div id="modal-create-plan">
				<form onSubmit={ this.submit }>
					<div className="row">
						<div className="col-md-12">
							<Input
								value={ this.state.form.name }
								name="name"
								placeholder="Nombre"
								label="Nombre del Plan"
								onChange={ (e: any) => this.change(e) } />
						</div>
						<div className="col-md-6">
							<Input
								type="decimal"
								value={ this.state.form.price }
								name="price"
								placeholder="Precio"
								label="Precio del Plan (US$)"
								onChange={ (e: any) => this.change(e) } />
						</div>
						<div className="col-md-6">
							<Input
								type="quantity"
								value={ this.state.form.quantity }
								name="quantity"
								placeholder="Cantidad"
								label="Cantidad de verificaciones"
								onChange={ (e: any) => this.change(e) } />
						</div>
					</div>
	      			<div className="text-center">
						<Button type="submit">
							Guardar
						</Button>
					</div>
				</form>
			</div>
		)
	}
}

export default ModalCreate;