import React from 'react';
import { connect } from 'react-redux';
import { RootState } from 'reducers';
import { RouteComponentProps, Link } from 'react-router-dom';
import { Globals, Constants, Colors } from 'utils';
import { Table, Pagination, Tooltip, Input, DatePicker, Select, Modal } from 'components';
import { CleanIcon, AddIcon, EditIcon, TrashIcon, ViewIcon } from 'assets/icons';
import { ReportService } from 'services';
import moment from 'moment';

interface Props extends RootState, RouteComponentProps {
	dispatch: any
}

const INITIAL_STATE = {
	search: '',
    status: '',
    since: '',
    until: '',
	case: '',
	since_attempts: '',
	until_attempts: '',
	origin: ''
}

class VerificationReport extends React.Component<Props> {

	timer: any = undefined;

	state = {
		header: [
			'Usuario',
			'Nombre',
			'Nº de Documento',
			'Origen',
			'Documento',
			'Fecha de Registro',
			'Fecha de Verificación',
			'Intentos',
			'Estado',
			'Motivo'
		],
		form: INITIAL_STATE,
		data: [],
		page: 1,
		last_page: 1,
		verifications_count: 0,
		approved_count: 0,
		rejected_count: 0,
		unfinish_count: 0,
		cases: []
	}

	componentDidMount() {
		this.props.dispatch({
			type: 'SET_HEADER',
			payload: {
				title: 'Reporte de Verificaciones',
				onBack: null
			}
		});

		this.props.dispatch({
			type: 'SET_SUBHEADER',
			payload: {
				title: ''
			}
		});

		this.loadCases();
		this.load();
	}

	loadCases = async () => {
		const res: any = await ReportService.getCases({
			withoutLoading: true
		});
		this.setState({
			cases: res.verification_cases || []
		});
	}

	load = async (withoutLoading: boolean = false,callback = () => {}) => {
		const res: any = await ReportService.admin.getVerifications({
			withoutLoading,
			page: this.state.page,
			...this.state.form
		});
		this.setState({
			data: res.verifications.rows,
			last_page: res.verifications.count,
			verifications_count: res.count || 0,
			approved_count: res.approved_count || 0,
			rejected_count: res.rejected_count || 0,
			unfinish_count: res.unfinish_count || 0
		});
	}

	change = (e: any,callback = () => {}) => {
		this.setState({
			page: 1,
			form: {
				...this.state.form,
				[e.target.name]: e.target.value
			}
		},callback);
	}

	reset = () => {
		this.setState({
			form: INITIAL_STATE,
			page: 1
		},() => this.load(true));
	}

	view = (item: any) => {
		window.open(window.location.href + '/view/' + item.id,'_blank');
	}

	getColor = (item: any) => {
		let color = '';

		switch(item.verification?.result) {
			case Constants.IDENTITY_VERIFICATION_RESULT.YES:
				return Colors.green;
			break;

			case Constants.IDENTITY_VERIFICATION_RESULT.NO:
				return Colors.danger;
			break;
		}

		return color;
	}

	render() {
		return (
			<div id="verifications-report">
				<div className="row row-filter">
					<div className="col-md-12">
						<div className="row">
							<div className="col-md-11 row">
								<div className="col-md-2">
									<Input
										value={ this.state.form.search }
										name="search"
										placeholder="Buscar"
										label="Buscar por nombre"
										className="input-table"
										onSubmit={ () => this.load() }
										onChange={ (e: any) => {
											clearTimeout(this.timer);
											this.timer = setTimeout(() => {
												this.load(true);
											},1000);
											this.change(e); 
										} } />
								</div>
								<div className="col-md-2">
									<DatePicker
										label="Desde"
										onChange={ (text: string) => {
											this.change({
												target: {
													value: text,
													name: 'since'
												}
											},() => this.load(true));
										} }
										value={ this.state.form.since }
									/>
								</div>
								<div className="col-md-2">
									<DatePicker
										label="Hasta"
										onChange={ (text: string) => {
											this.change({
												target: {
													value: text,
													name: 'until'
												}
											},() => this.load(true));
										} }
										value={ this.state.form.until }
									/>
								</div>
								<div className="col-md-2">
									<Select
										color="white"
										placeholder="Seleccionar"
										label="Origen"
										name="origin"
										onChange={ (e: any) => this.change(e,async () => {
											this.load(true);
										}) }
										value={ this.state.form.origin }
										options={ [
											{ value: Constants.ORIGINS.ANDROID.toString(), label: 'Android' },
											{ value: Constants.ORIGINS.IOS.toString(), label: 'iOS' },
											{ value: Constants.ORIGINS.WEB.toString(), label: 'Web' }
										] } />
								</div>
								<div className="col-md-4 row">
									<div className="col-md-6">
										<Input
											value={ this.state.form.since_attempts }
											name="since_attempts"
											placeholder="Intentos"
											label="Intentos (Desde)"
											className="input-table input-attempts"
											onChange={ (e: any) => {
												clearTimeout(this.timer);
												this.timer = setTimeout(() => {
													this.load(true);
												},1000);
												this.change(e); 
											} } />
									</div>
									<div className="col-md-6">
										<Input
											value={ this.state.form.until_attempts }
											name="until_attempts"
											placeholder="Intentos"
											label="Intentos (Hasta)"
											className="input-table input-attempts"
											onChange={ (e: any) => {
												clearTimeout(this.timer);
												this.timer = setTimeout(() => {
													this.load(true);
												},1000);
												this.change(e); 
											} } />
									</div>
								</div>
							</div>
							<div className="col-md-1">
								{
									this.state.form != INITIAL_STATE && (
										<button className="btn btn-clean" onClick={ () => this.reset() }>
											<img src={ CleanIcon } />
										</button>
									)
								}
							</div>
						</div>
					</div>
				</div>

				<div className="row row-filter-bottom">
					<div className="col-md-6">
						<div className="messages-count">
							<p><strong>Total Registros:</strong> { this.state.verifications_count }</p>
						</div>
						<div className="messages-count">
							<p><strong>Aprobados:</strong> { this.state.approved_count }</p>
						</div>
						<div className="messages-count">
							<p><strong>Rechazados:</strong> { this.state.rejected_count }</p>
						</div>
						<div className="messages-count">
							<p><strong>No Completados:</strong> { this.state.unfinish_count }</p>
						</div>
					</div>
					<div className="col-md-6">
						<div className="row">
							<div className="col-md-3">
								<p className="label-inline">Estado</p>
							</div>
							<div className="col-md-3 select-inline">
								<Select
									color="white"
									placeholder="Seleccionar"
									name="status"
									onChange={ (e: any) => this.change(e,() => this.load(true)) }
									value={ this.state.form.status }
									options={ [
										{ value: Constants.IDENTITY_VERIFICATION_RESULT.YES.toString(), label: 'Verificados' },
										{ value: Constants.IDENTITY_VERIFICATION_RESULT.NO.toString(), label: 'Rechazados' },
										{ value: Constants.IDENTITY_VERIFICATION_RESULT.UNFINISH.toString(), label: 'No completados' },
									] } />
							</div>
							<div className="col-md-3">
								<p className="label-inline">Filtrar por motivo</p>
							</div>
							<div className="col-md-3 select-inline">
								<Select
									color="white"
									placeholder="Seleccionar"
									name="case"
									onChange={ (e: any) => this.change(e,() => this.load(true)) }
									value={ this.state.form.case }
									options={ this.state.cases.map((item: any) => {
										return {
											value: item.id,
											label: item.name
										}
									}) } />
							</div>
						</div>
					</div>
				</div>

				<Table title="Reporte de Verificaciones" data={ this.state.data.length } header={ this.state.header }>
					{ this.state.data.map((i: any,index: number) => {
						return (
							<tr key={ index } onClick={ () => this.view(i) }>
								<td>{ i.user != null ? (i.user?.name + ' ' + i.user?.lastname) : '-' }</td>
								<td>
									{
										(i.request_name || i.request_lastname) && (
											<p className="name">{ (i.request_name || '') + ' ' + (i.request_lastname || '') }</p>
										)
									}
									<p className="email">{ i.email }</p>
								</td>
								<td>{ i.verification?.document }</td>
								<td>{ i.verification?.origin?.name || '-' }</td>
								<td>{ i.verification?.document_type?.name || '-' }</td>
								<td>{ moment(i.created_at).isValid() ? moment(i.created_at).format('DD/MM/YYYY HH:mm') : '-' }</td>
								<td>{ i.verification != null && moment(i.verification?.created_at).isValid() ? moment(i.verification?.created_at).format('DD/MM/YYYY HH:mm') : '-' }</td>
								<td>{ i.verifications_count || 0 }</td>
								<td className="result" style={{
									color: this.getColor(i)
								}}>{ i.verification?.result_text || '-' }</td>
								<td className="container-tags">
									{
										i.verification?.cases?.map((item: any) => {
											return (
												<div className="tag">
													<p>{ item.case?.name }</p>
												</div>
											)
										})
									}
								</td>
							</tr>
						)
					}) }
				</Table>

				<Pagination 
					pages={ this.state.last_page } 
					active={ this.state.page }
					onChange={ async (page: number) => {
						await this.setState({
							page: page
						});
						this.load();
					} } />
			</div>
		)
	}
}

export default connect((state: RootState) => {
	return {
		user: state.user
	}
})(VerificationReport);