import React from 'react';
import { connect } from 'react-redux';
import { RootState } from 'reducers';
import { RouteComponentProps, Link } from 'react-router-dom';
import { Globals, Constants, Colors } from 'utils';
import { 
	SelectPlan,
	MessagesImage,
	MessagesImage2,
	PlanImage,
	CalendarImage,
	NoPlanImage
} from 'assets/img';
import { PlanService } from 'services';
import { Modal, DatePicker } from 'components';
import moment from 'moment';
import {
	Home1Icon,
	Home2Icon,
	Home3Icon,
	Home4Icon,
	Messages1Icon,
	Messages2Icon,
	Messages3Icon,
	SandClockIcon
} from 'assets/icons';
import { Table, Select } from 'components';
import { 
	Chart, 
	LineController,
	CategoryScale,
	LinearScale,
	PointElement,
	LineElement,
	Tooltip,
	DoughnutController,
	ArcElement
} from 'chart.js';

Chart.register(
	LineController,
	CategoryScale,
	LinearScale,
	PointElement,
	LineElement,
	Tooltip,
	ArcElement,
	DoughnutController
);

const TYPES_CHART = {
	MONTH: 1,
	WEEK: 2
}

interface Props extends RootState, RouteComponentProps {
	dispatch: any
}

class Home extends React.Component<Props> {

	state = {
		plan_users: [],
		loading: true,
		verifications_remaining: 0,
		total_verifications: 0,
		dates: [],
		plans: [],
		actual_plan: null,
		header: [
			'Fecha',
			'Nombre',
			'Documento',
			'Tipo de Documento',
			'Resultado'
		],
		chart_data: [],
		chart_labels: [],
		type_chart: TYPES_CHART.MONTH,
		chart: null,
		chart_circle: null,
		chart_top: null,
		chart_bottom: null,
		chart_top_data: [],
		chart_top_labels: [],
		chart_bottom_data: [],
		chart_bottom_labels: [],
		chart_total_approved: 0,
		chart_total_rejected: 0,
		chart_bottom_total: 0,
		chart_bottom_total_approved: 0,
		chart_bottom_total_rejected: 0,
		total_verification_realized: 0,
		since: moment().subtract(1,'week').toDate(),
		until: moment().toDate()
	}

	componentDidMount() {
		this.props.dispatch({
			type: 'SET_HEADER',
			payload: {
				title: 'Inicio',
				onBack: null
			}
		});

		this.props.dispatch({
			type: 'SET_SUBHEADER',
			payload: {
				title: ''
			}
		});

		this.load();
	}

	load = async (withoutLoading = false) => {
		if (moment(this.state.since) > moment(this.state.until)) {
			Globals.showError("Las fechas seleccionadas no son válidas");
			return;
		}

		// @ts-ignore
		this.state.chart?.destroy();

		// @ts-ignore
		this.state.chart_top?.destroy();

		// @ts-ignore
		this.state.chart_bottom?.destroy();

		// @ts-ignore
		this.state.chart_circle?.destroy();

		await this.setState({
			dates: [],
			plans: []
		});
		const res: any = await PlanService.get({
			withoutLoading,
			type_chart: this.state.type_chart,
			since: moment(this.state.since).isValid() ? moment(this.state.since).format('YYYY-MM-DD') : '',
			until: moment(this.state.until).isValid() ? moment(this.state.until).format('YYYY-MM-DD') : ''
		});
		let dates: any = [...this.state.dates];
		let plans: any = [...this.state.plans];

		for (let i = 0; i < res.plan_users.length; i++) {
			if (plans.map((i: any) => i.id).indexOf(res.plan_users[i].plan_id) == -1) {
				plans.push({
					id: res.plan_users[i].plan_id,
					name: res.plan_users[i].plan?.name || ''
				});
			}

			const index = dates.map((i: any) => i.expiration_date).indexOf(res.plan_users[i].expiration_date);
			if (index == -1) {
				dates.push({
					expiration_date: res.plan_users[i].expiration_date,
					verifications_remaining: res.plan_users[i].verifications_remaining
				});
			}
			else {
				dates[index] = {
					...dates[index],
					verifications_remaining: dates[index].verifications_remaining + res.plan_users[i].verifications_remaining
				}
			}
		}

		this.setState({
			plan_users: res.plan_users || [],
			loading: false,
			verifications_remaining: res.verifications_remaining,
			total_verifications: res.total_verifications,
			dates,
			plans,
			actual_plan: res.actual_plan,
			chart_data: res.chart_data || [],
			chart_labels: res.chart_labels || [],
			chart_top_data: res.chart_top_data || [],
			chart_top_labels: res.chart_top_labels || [],
			chart_bottom_data: res.chart_bottom_data || [],
			chart_bottom_labels: res.chart_bottom_labels || [],
			chart_total_approved: res.chart_total_approved || 0,
			chart_total_rejected: res.chart_total_rejected || 0,
			chart_bottom_total: res.chart_bottom_total || 0,
			chart_bottom_total_approved: res.chart_bottom_total_approved || 0,
			chart_bottom_total_rejected: res.chart_bottom_total_rejected || 0,
			total_verification_realized:  res.total_verification_realized || 0
		},() => {
			try {
				this.setState({
					// @ts-ignore
					chart: new Chart(document.getElementById("chart"), {
						type: 'line',
						data: {
						  labels: this.state.chart_labels || [],
						  datasets: [
						    {
						      label: 'Aprobados',
						      data: this.state.chart_data[0] || [],
						      borderColor: Colors.chartAccepted,
						      backgroundColor: Colors.chartAccepted,
						      pointStyle: 'circle',
						      pointRadius: 5,
						      pointHoverRadius: 10
						    },
						    {
						      label: 'Rechazados',
						      data: this.state.chart_data[1] || [],
						      borderColor: Colors.chartRejected,
						      backgroundColor: Colors.chartRejected,
						      pointStyle: 'circle',
						      pointRadius: 5,
						      pointHoverRadius: 10
						    }
						  ]
						},
						options: {
							responsive: true,
							maintainAspectRatio: false,
						    scales: {
						        y: {
					                beginAtZero: true
					            }
						    },
						    elements: {
								line: {
									tension: 0.4
								}
							},
						}
					}),
					// @ts-ignore
					chart_bottom: new Chart(document.getElementById("chart-bottom"), {
						type: 'line',
						data: {
						  labels: this.state.chart_bottom_labels || [],
						  datasets: [
						    {
						      label: 'Aprobados',
						      data: this.state.chart_bottom_data[0] || [],
						      borderColor: Colors.chartAccepted,
						      backgroundColor: Colors.chartAccepted,
						      pointStyle: 'circle',
						      pointRadius: 3,
						      pointHoverRadius: 6
						    },
						    {
						      label: 'Rechazados',
						      data: this.state.chart_bottom_data[1] || [],
						      borderColor: Colors.chartRejected,
						      backgroundColor: Colors.chartRejected,
						      pointStyle: 'circle',
						      pointRadius: 3,
						      pointHoverRadius: 6
						    }
						  ]
						},
						options: {
							responsive: true,
							maintainAspectRatio: false,
						    scales: {
						        y: {
					                beginAtZero: true
					            }
						    }
						}
					}),
					// @ts-ignore
					chart_top: new Chart(document.getElementById("chart-top"), {
						type: 'line',
						data: {
						  labels: this.state.chart_top_labels || [],
						  datasets: [
						    {
						      label: 'Verificaciones',
						      data: this.state.chart_top_data || [],
						      borderColor: Colors.chartAccepted,
						      backgroundColor: Colors.chartAccepted,
						      pointStyle: 'circle',
						      pointRadius: 5,
						      pointHoverRadius: 10
						    }
						  ]
						},
						options: {
							responsive: true,
							maintainAspectRatio: false,
							elements: {
								line: {
									tension: 0.4
								}
							},
						    scales: {
						        y: {
					                beginAtZero: true
					            }
						    }
						}
					}),
					// @ts-ignore
					// chart_circle: new Chart(document.getElementById("chart-circle"), {
					// 	type: 'doughnut',
					// 	data: {
					// 	  labels: ['Verificaciones Realizadas','Verificaciones Restantes'],
					// 	  datasets: [
					// 	    {
					// 	      label: 'Dataset',
					// 	      data: [this.state.total_verifications - this.state.verifications_remaining,this.state.verifications_remaining],
					// 	      backgroundColor: [Colors.chartRed,Colors.chartBlue]
					// 	    }
					// 	  ]
					// 	},
					// 	options: {
					// 		responsive: true,
					// 		maintainAspectRatio: false
					// 	}
					// })
				});
			}
			catch(e) {
				console.log(e);
			}
		});
	}
	
	render() {
		const { loading } = this.state;
		const plan_users: any = this.state.plan_users;
		const dates: any = this.state.dates;
		const plans: any = this.state.plans;
		const actual_plan: any = this.state.actual_plan;

		return (
			<div id="home">
				{
					!loading && plan_users.length == 0 && (
						<div className="container-white">
							<p>No tienes un plan activo</p>
							<h3>¡Empieza ahora a disfrutar de { process.env.REACT_APP_NAME }!</h3>
							<img src={ NoPlanImage } className="no-plan-image" />
						</div>
					)
				}
				{
					!loading && plan_users.length > 0 && (
						<>
							<div className="row">
								<div className="col-md-12">
									<div className="row-filter">
										<p>Periodo:</p>
										<DatePicker
											customFormat="DD [de] MMM [de] YYYY"
											onChange={ (text: string) => {
												this.setState({
													since: text
												},() => this.load(true));
											} }
											value={ this.state.since }
										/>
										<p className="to">a</p>
										<DatePicker
											customFormat="DD [de] MMM [de] YYYY"
											onChange={ (text: string) => {
												this.setState({
													until: text
												},() => this.load(true));
											} }
											value={ this.state.until }
										/>
									</div>

									<div className="container-white-data">
										<h3>Verificaciones totales</h3>
										<div className="container-chart">
											<div className="container-chart-top">
												<canvas id="chart-top"></canvas>
											</div>
											<div className="container-legends">
												<p className="legend" style={{ color: Colors.chartAccepted }}><strong>Verificaciones Totales:</strong> { this.state.total_verification_realized }</p>
											</div>
										</div>
									</div>
								</div>
								{/*<div className="col-md-5">
									<div className="container-circle">
										<canvas id="chart-circle"></canvas>
									</div>
									<div className="container-legends text-center">
										<p className="legend" style={{ color: Colors.chartRed }}><strong>Verificaciones Realizadas:</strong> { this.state.total_verifications - this.state.verifications_remaining }</p>
										<p className="legend" style={{ color: Colors.chartBlue }}><strong>Verificaciones Restantes:</strong> { this.state.verifications_remaining  }</p>
									</div>
								</div>*/}
								<div className="col-md-12">
									<div className="container-white-data">
										<div className="row">
											<div className="col-md-6">
												<h3>Estado de las verificaciones</h3>
											</div>
											<div className="col-md-6 text-right">
												<Select
													color="white"
													placeholder="Seleccionar"
													name="type_chart"
													onChange={ (e: any) => {
														this.setState({
															type_chart: e.target.value
														},() => this.load());
													} }
													value={ this.state.type_chart }
													options={ [
														{ value: TYPES_CHART.MONTH.toString(), label: 'Mes' },
														{ value: TYPES_CHART.WEEK.toString(), label: 'Semana' },
													] } />
											</div>
										</div>
										<div className="container-chart">
											<div className="container-chart-bottom">
												<canvas id="chart"></canvas>
											</div>
											<div className="container-legends">
												<p className="legend" style={{ color: Colors.chartAccepted }}><strong>Usuarios Aprobados:</strong> { this.state.chart_total_approved }</p>
												<p className="legend" style={{ color: Colors.chartRejected }}><strong>Usuarios Rechazados:</strong> { this.state.chart_total_rejected  }</p>
											</div>
										</div>
									</div>
								</div>
								<div className="col-md-12">
									<div className="container-white-data">
										<h3>Revisión de usuarios de hoy</h3>
										<div className="container-chart">
											<div className="container-chart-top">
												<canvas id="chart-bottom"></canvas>
											</div>
											<div className="container-legends">
												<p className="legend" style={{ color: Colors.chartGreen }}><strong>Usuarios Totales:</strong> { this.state.chart_bottom_total }</p>
												<p className="legend" style={{ color: Colors.chartAccepted }}><strong>Usuarios Aprobados:</strong> { this.state.chart_bottom_total_approved }</p>
												<p className="legend" style={{ color: Colors.chartRejected }}><strong>Usuarios Rechazados:</strong> { this.state.chart_bottom_total_rejected  }</p>
											</div>
										</div>
									</div>
								</div>
							</div>
						</>
					)
				}
			</div>
		)
	}
}

export default connect((state: RootState) => {
	return {
		user: state.user
	}
})(Home);