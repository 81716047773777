import { Api } from 'utils';

class PlanService {

	static get = async (params?: any) => Api.createResponse('client/plan',params);
	static save = async (params?: any) => Api.createResponse('client/plan/save',params);
	static getPlanList = async (params?: any) => Api.createResponse('client/plan/plan-list',params);
	
	static admin = {
		get: async (params?: any) => Api.createResponse('admin/plans',params),
		save: async (params?: any) => Api.createResponse('admin/plans/save',params),
		saveUser: async (params?: any) => Api.createResponse('admin/plans/save-user',params),
		delete: async (params?: any) => Api.createResponse('admin/plans/delete',params),
	}

}

export default PlanService;