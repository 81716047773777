export default {
	danger: '#e53935',
	green: '#136f40',
	yellow: '#e7b032',
	swal: '#1022aa',
	orange: '#FB8C00',
	blue: '#1976D2',
	orangeTable: '#FFCC80',
	greenTable: '#8BC34A',
	redTable: '#EF5350',
	blueTable: '#42A5F5',
	white: '#fff',
	chartRed: '#ff6384',
	chartGreen: '#49aa43',
	chartAccepted: '#9eecc2',
	chartRejected: '#2637b4',
	chartMarine: '#2453a1',
	chartYellow: '#febd25',
	green_light: '#46fca5'
}