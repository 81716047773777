import React from 'react';
import { Input, Textarea, Select, Button, PaperClip, DatePicker } from 'components';
import { ProfileService, AuthService } from 'services';
import moment from 'moment';
import { connect } from 'react-redux';
import { RootState } from 'reducers';
import { Globals, Constants } from 'utils';
import { RouteComponentProps } from 'react-router-dom';
import { Menu as MenuIcons, CopyIcon, PhoneIcon, WebIcon, InstagramIcon, FacebookIcon, LocationIcon, TimeIcon, AddIcon, TrashIcon, CodingIcon, UserIcon } from 'assets/icons';
import { WhiteImage } from 'assets/img';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { CopyBlock, dracula } from "react-code-blocks";

interface Props extends RootState, RouteComponentProps {
	dispatch: any
}

class Api extends React.Component<Props> {

	state = {
		user: null,
		copied: false,
		bearer_token: ''
	}

	componentDidMount() {
		this.props.dispatch({
			type: 'SET_HEADER',
			payload: {
				title: 'Integración API',
				onBack: null
			}
		});

		this.props.dispatch({
			type: 'SET_SUBHEADER',
			payload: {
				title: ''
			}
		});

		this.load();
	}

	load = async (withoutLoading = false) => {
		const res: any = await ProfileService.get({
			withoutLoading
		});
		this.setState({
			user: res.user,
			bearer_token: res.user?.bearer_token || ''
		});
	}

	setActive = (e: any, tab: number) => {
		e.preventDefault();
		this.setState({
			tab
		});
	}

	generateToken = () => {
		Globals.confirm('Si genera una nueva llave, la actual dejara de funcionar. ¿Desea continuar?',async () => {
			const res: any = await ProfileService.generateToken();
			if (res.user) {
				this.setState({
					bearer_token: res.user?.bearer_token
				});
			}
			Globals.showSuccess("Se ha generado una nueva llave");
			this.load(true);
		});
	}
	
	render() {
		return (
			<div id="profile">
				<div className="container-api">
					<p className="label-api">Clave de Api</p>
					<div className="container-copy">
						<p>{ this.state.bearer_token }</p>
						<CopyToClipboard text={ this.state.bearer_token } onCopy={ () => {
							this.setState({ copied: true },() => {
								setTimeout(() => {
									this.setState({ copied: false });
								},1500);
							});
						} }>
							<img src={ CopyIcon } />
						</CopyToClipboard>
					</div>
				</div>
				{
					this.state.copied && (
						<p className="copied">Copiada al portapapeles</p>
					)
				}
				<div className="container-regenerate">
					<button className="btn-regenerate" onClick={ () => this.generateToken() }>
						Regenerar
					</button>
				</div>

				<div className="container-documentation">
					{/*
					// @ts-ignore */}
					<CopyBlock
			          text={`axios.post("${ process.env.REACT_APP_BASE_API }identity/check",{
	selfie: (Binary),
	document_photo: (Binary),
	name: 'Pedro Perez',
	document: 'V12345678', // Cédula requiere el tipo de documento al inicio
	birthdate: '1990-01-01', // Formato YYYY-MM-DD
	document_type_id: 1 // Cédula: 1, Pasaporte: 2
},{
	headers: {
		"Authorization": "Bearer ${ this.state.bearer_token }",
		"Content-Type": "multipart/form-data"
	}
});`}
			          language={'jsx'}
			          showLineNumbers={true}
			          theme={dracula}
			          wrapLines={true}
			          codeBlock
			        />
			    </div>
			</div>
		)
	}
}

export default connect((state: RootState) => {
	return {
		user: state.user
	}
})(Api);